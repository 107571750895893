import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Form from "@rjsf/mui";
import { JSONSchema7 } from "json-schema";
import { parse, format } from "date-fns";
import ReactGA from "react-ga4";
// helpers
import { getFilteredStorageSchemaProps } from "./helpers/getFilteredStorageSchemaProps";
import { getDateFnsLocale } from "../../common/utils/helpers/localeHelpers/getDateFnsLocale";
import { isObjectEmpty } from "../../common/utils/helpers/isObjectEmpty";
import { getSchemaValidatorLocale } from "../../common/utils/helpers/localeHelpers/getSchemaValidatorLocale";
// hooks
import { useActions } from "../../common/hooks/useActions";
// actions
import { bookingActionCreators } from "./bookingModule";
import { globalActionCreators } from "../../common/store/modules/globalModule";
// components
import CustomFormDropdown from "../../components/ui/AppForm/CustomFormDropdown";
import SectionHeadingBox from "../../components/ui/SectionHeadingBox";
import CustomFormDateAndTimeBox from "../../components/ui/AppForm/CustomFormDateAndTimeBox";
import AppButton from "../../components/ui/AppButton";
import AppSummary from "../../components/AppSummary";
// booking components
import BookingPaymentModal from "./BookingPaymentModal";
import PreBookingError from "./PreBookingError";
// Schema components
import InfoFieldsTemplate from "./BookingSchemas/InfoFieldsTemplate";
import InfoCustomInputField from "./BookingSchemas/InfoCustomInputField";
import InfoCustomTextareaField from "./BookingSchemas/InfoCustomTextareaField";
import InfoCustomDropzone from "./BookingSchemas/InfoCustomDropzone";
import SummaryFieldsTemplate from "./BookingSchemas/SummaryFieldsTemplate";

export default function Booking() {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [bookingSchemaFormData, setBookingSchemaFormData] = useState(location?.state?.offerFormData);
  const [infoSchemaFormData, setInfoSchemaFormData] = useState(null);
  const [summaryUiSchema, setSummaryUiSchema] = useState(null);
  const [extraOptionsSchemaFormData, setExtraOptionsSchemaFormData] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  // custom error handling for extra-options schema & "options" property
  const [extraOptionsValidate, setExtraOptionsValidate] = useState(true);

  const language = localStorage.getItem("i18nextLng");
  // @ts-ignore
  const bookingState = JSON.parse(sessionStorage.getItem("bookingState"));

  // states
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const bookingSummarySchema = useSelector((state: any) => state.booking?.bookingSummarySchema);
  const totalPriceData = useSelector((state: any) => state.booking?.totalPriceData);
  const personalInfoSchema = useSelector((state: any) => state.booking?.personalInfoSchema);
  const extraOptionsSchema = useSelector((state: any) => state.booking?.extraOptionsSchema);
  const apiUrl = useSelector((state: any) => state.global?.apiUrl);
  const bookingError = useSelector((state: any) => state.booking?.bookingError);
  const showPaymentModal = useSelector((state: any) => state.booking?.showPaymentModal);
  const isBookingLoading = useSelector((state: any) => state.booking?.isBookingLoading);

  // actions
  const getAvailabilityBookingFormData = useActions(bookingActionCreators?.getAvailabilityBookingFormDataAction, []);
  const loadInitialBookingFormData = useActions(bookingActionCreators?.loadInitialBookingFormDataAction, []);
  const bookOffer = useActions(bookingActionCreators?.bookOfferAction, []);
  const toggleBookingError = useActions(bookingActionCreators?.toggleBookingErrorAction, []);

  const infoUiSchema = {
    ...personalInfoSchema?.ui_schema,
    first_name: {
      "ui:field": "textinput"
    },
    last_name: {
      "ui:field": "textinput"
    },
    email: {
      "ui:field": "textinput"
    },
    phone: {
      "ui:field": "textinput"
    },
    comment: {
      "ui:field": "textinput"
    },
    delivery_address: {
      "ui:field": "textarea"
    },
    attachment: {
      "ui:field": "dropzone"
    }
  };

  const extraOptionsUiSchema = {
    ...extraOptionsSchema?.ui_schema,
    options: {
      "ui:widget": "radio",
      "ui:options": {
        props: {
          fullWidth: true,
          showStar: true
        }
      }
    },
    "ui:submitButtonOptions": {
      norender: true
    }
  };

  useEffect(() => {
    // @ts-ignore
    const sessionStorageInfoSchema = JSON.parse(sessionStorage.getItem("sessionStorageInfoSchema"));
    if (sessionStorageInfoSchema && personalInfoSchema?.json_schema) {
      const filteredSessionStorageData = getFilteredStorageSchemaProps(
        sessionStorageInfoSchema,
        personalInfoSchema?.json_schema?.properties
      );
      setInfoSchemaFormData(filteredSessionStorageData);
    }
  }, [personalInfoSchema]);

  useEffect(() => {
    // Disable the Language menu on the Checkout page
    dispatch(globalActionCreators?.toggleLanguageMenuDisableAction(true));

    window.scrollTo(0, 0);

    return () => {
      toggleBookingError(false);
      // Enable the Language menu when the user leaves the Checkout page
      dispatch(globalActionCreators?.toggleLanguageMenuDisableAction(false));
    };
  }, []);

  useEffect(() => {
    // If it is initial render, when user comes from Offer page, based on dependency perform additional actions - load initial Booking Form Data with location.state data
    if (location?.state?.slot) {
      // store availability data in Session Storage
      sessionStorage.setItem("bookingState", JSON.stringify({ state: location?.state }));
    } else {
      // If page is refreshed update Booking Schema Form Data with availability data stored in Session Storage
      setBookingSchemaFormData(bookingState?.state.offerFormData);
    }

    return () => {
      // remove availability data from Session Storage when User leaves Checkout page
      sessionStorage.removeItem("bookingState");
    };
  }, []);

  useEffect(() => {
    // If it is initial render, when user comes from Offer page, based on dependency perform additional actions - load initial Booking Form Data with location.state data
    if (isInitialRender && apiUrl && location?.state?.slot) {
      loadInitialBookingFormData(bookingSchemaFormData, location?.state?.offer?.offer_code, location?.state?.slot);
      // Set isInitialRender to false after the first render
      setIsInitialRender(false);
    }

    // If it is initial render, when page is refreshed, based on dependency perform additional actions - load initial Booking Form Data with Booking State data from Session storage
    if (isInitialRender && apiUrl && !location?.state?.slot && bookingState) {
      loadInitialBookingFormData(
        bookingState?.state.offerFormData,
        bookingState?.state.offer?.offer_code,
        bookingState?.state.slot
      );
      // Set isInitialRender to false after the first render
      setIsInitialRender(false);
    }
  }, [apiUrl]);

  useEffect(() => {
    // If it's not the initial render, based on dependency perform additional actions - on changed Summary fields check/get Availability Booking Form Data
    if (!isInitialRender && bookingSchemaFormData) {
      getAvailabilityBookingFormData(
        bookingSchemaFormData,
        bookingState?.state?.offer?.offer_code,
        bookingState?.state?.slot
      );
    }
  }, [bookingSchemaFormData]);

  useEffect(() => {
    // when user change language (on Checkout page) Booking Form Data will be loaded with language selected and URL will be changed to include selected language
    if (!isInitialRender) {
      loadInitialBookingFormData(
        bookingState?.state.offerFormData,
        bookingState?.state.offer?.offer_code,
        bookingState?.state.slot
      );
      navigate(`/${language}/offers/${bookingState?.state?.offer?.offer_code}/checkout`, { replace: true });
    }
  }, [language]);

  useEffect(() => {
    // when user go directly to checkout page (/language/offers/offerCode/checkout) it will be redirected to Offer page (/language/offers/offerCode) or Not Found page if offer code is for non existing offer
    if (!location?.state?.slot && !bookingState) {
      const offerCode = location?.pathname?.split("offers/")?.pop()?.split("/")[0];
      navigate(`/${language}/offers/${offerCode}`, { replace: true });
    }
  }, []);

  useEffect(() => {
    // Handling UI Schema definition of properties
    // TBD: MOVE THIS TO SEPARATE HELPER AFTER IS APPROVED AND AFTER BACKEND CHANGES (to include category to JSON Schema properties)
    const uiSchemaFields: any = {};

    if (bookingSummarySchema?.json_schema?.properties) {
      // Filtered properties for dropdowns fields
      // Filter the properties based on the criteria
      const filteredDropdownProperties = Object.keys(bookingSummarySchema?.json_schema.properties).filter(
        key => bookingSummarySchema.json_schema.properties[key].enum
      );

      filteredDropdownProperties.forEach((key: any) => {
        uiSchemaFields[key] = {
          "ui:field": "dropdown",
          "ui:options": {
            props: {}
          }
        };
      });
      // Filtered properties for date fields
      const filteredDateProperties = Object.keys(bookingSummarySchema?.json_schema.properties).filter(
        key => bookingSummarySchema.json_schema.properties[key].format === "date"
      );

      filteredDateProperties.forEach((key: any) => {
        uiSchemaFields[key] = {
          "ui:field": "date",
          "ui:options": {
            props: {
              time: bookingState?.state?.slot,
              date:
                bookingSchemaFormData?.start_date &&
                format(parse(bookingSchemaFormData?.start_date, "yyyy-MM-dd", new Date()), "EEEE, MMMM d, yyyy", {
                  locale: getDateFnsLocale()
                })
            }
          }
        };
      });
      // ADD OTHER UI DEFINITION TO SCHEMA
      uiSchemaFields["ui:options"] = { label: false }; // hide title and description,
      uiSchemaFields["ui:submitButtonOptions"] = {
        props: {
          disabled: true,
          style: {
            display: "none"
          }
        },
        submitText: "Check"
      };

      setSummaryUiSchema(uiSchemaFields);
    }
  }, [bookingSummarySchema]);

  useEffect(() => {
    // GA4 - Track when user comes to the Checkout page
    if (bookingState?.state?.offer?.offer_code) {
      ReactGA.gtag("event", "begin_checkout", {
        items: [
          {
            item_id: bookingState?.state?.offer?.offer_code,
            item_name: bookingState?.state?.offer?.title
          }
        ]
      });
    }
  }, []);

  // TBD: Move this to separate helper & write test for it
  const checkIfExtraOptionsValidate = (): boolean => {
    const isOptionsRequired = extraOptionsSchema?.json_schema?.required?.includes("options");
    if (isOptionsRequired) {
      return !isObjectEmpty(extraOptionsSchemaFormData || {});
    } else {
      return true;
    }
  };

  const onSubmitInfoForm = (event: any) => {
    const isValidExtraOptions: boolean = checkIfExtraOptionsValidate();

    if (!isValidExtraOptions) {
      setExtraOptionsValidate(false);
      return;
    } else {
      !extraOptionsValidate && setExtraOptionsValidate(true);
    }

    // set data to session storage - in order to pre-fill form for the next booking
    sessionStorage.setItem("sessionStorageInfoSchema", JSON.stringify(infoSchemaFormData));

    // book offer action
    bookOffer(
      event?.formData,
      bookingSchemaFormData,
      extraOptionsSchemaFormData,
      bookingState?.state?.offer?.offer_code,
      bookingState?.state?.slot,
      hotelInfo?.code,
      navigate
    );
  };

  const onChangePersonalInfoSchema = (event: any, field: string | undefined) => {
    const fieldName = field?.split("root_").pop() || ""; // remove "root_" from field name ("root_first_name" for example) in order to match with formData property
    // fixed validation issue with "touched" inputs, which are not required but can not be empty string (because of rjsf validation)
    if (event.formData[fieldName] === "") {
      const spreadObj = { ...event.formData };
      delete spreadObj[fieldName];
      return setInfoSchemaFormData(spreadObj);
    }
    return setInfoSchemaFormData(event.formData);
  };

  // --> Fields for render
  const bookingSchemaFields = {
    dropdown: CustomFormDropdown,
    date: CustomFormDateAndTimeBox
  };

  const personalSchemaFields: any = {
    textinput: InfoCustomInputField,
    textarea: InfoCustomTextareaField,
    dropzone: InfoCustomDropzone
  };

  // END OF --> Fields for render

  return (
    <Grid
      className="booking-form"
      // changes to be handled as on Offer Details
      mt={{ xs: 2, sm: 3 }}
      mb={{ xs: 4, sm: 5 }}
      pl={{ xs: 5 }}
      pr={{ xs: 5 }}
      container
      justifyContent="center"
    >
      {bookingError ? (
        <Grid container justifyContent="center" mt={2}>
          <Grid item xs={10} md={6}>
            <PreBookingError />
          </Grid>
        </Grid>
      ) : (
        <>
          <SectionHeadingBox heading={bookingState?.state?.offer?.title} subheading={t("bookingForm.subheading")} />

          {bookingSummarySchema?.json_schema && summaryUiSchema && (
            <Grid container columnSpacing={{ xs: 0, sm: 3, md: 6 }}>
              <Grid item xs={12} sm={12} md={6} mt={0} mb={0}>
                <Box className="booking-form__title">{t("bookingForm.summaryTitle")}</Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} m={0}>
                  <Form
                    onChange={e => {
                      // turn on global loader
                      dispatch(globalActionCreators?.toggleGlobalLoaderAction(true));
                      setBookingSchemaFormData(e.formData);
                    }}
                    formData={bookingSchemaFormData}
                    schema={bookingSummarySchema?.json_schema as JSONSchema7}
                    uiSchema={summaryUiSchema}
                    validator={getSchemaValidatorLocale()}
                    fields={bookingSchemaFields}
                    templates={{ ObjectFieldTemplate: SummaryFieldsTemplate }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={6} mt={0} mb={0} position={"relative"}>
                <div className="vertical-divider"></div>
                <Box className="summary__details" pl={{ xs: 0, sm: 0, md: 6 }}>
                  <Box className="booking-form__title">{t("bookingForm.totalPriceTitle")}</Box>
                  <Box>
                    {totalPriceData?.status === "available" && totalPriceData?.price?.length > 0 ? (
                      totalPriceData.price.map((priceRow: any, index: number) => {
                        return (
                          <AppSummary
                            key={index}
                            label={priceRow?.label}
                            currency={priceRow?.currency}
                            textStyle={priceRow?.text_style}
                            amount={priceRow?.amount}
                          />
                        );
                      })
                    ) : (
                      <Box className="booking-form__message" mt={2}>
                        {t("bookingForm.offerUnavailableMessage")}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {extraOptionsSchema?.json_schema && extraOptionsUiSchema && (
            <Grid container columnSpacing={{ xs: 0, sm: 3, md: 6 }} mt={{ xs: 2, sm: 3, md: 5 }}>
              <Grid item xs={12} sm={12} md={6} mt={0} mb={0}>
                <Box
                  sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                  m={0}
                  className="booking-form__extra-options"
                >
                  <Form
                    onChange={e => {
                      setExtraOptionsSchemaFormData(e.formData);
                    }}
                    formData={extraOptionsSchemaFormData}
                    schema={extraOptionsSchema?.json_schema as JSONSchema7}
                    uiSchema={extraOptionsUiSchema}
                    validator={getSchemaValidatorLocale()}
                  />
                </Box>
                {!extraOptionsValidate && <Box className="booking-form__extra-options__error-msg">mandatory field</Box>}
              </Grid>
            </Grid>
          )}
          {personalInfoSchema?.json_schema && (
            <Grid container mt={{ xs: 3, sm: 6, md: 10 }}>
              <Grid item xs={12}>
                <Form
                  formData={infoSchemaFormData}
                  onChange={onChangePersonalInfoSchema}
                  schema={personalInfoSchema?.json_schema as JSONSchema7}
                  uiSchema={infoUiSchema}
                  validator={getSchemaValidatorLocale()}
                  fields={personalSchemaFields}
                  templates={{ ObjectFieldTemplate: InfoFieldsTemplate }}
                  onSubmit={onSubmitInfoForm}
                  showErrorList={false}
                  noHtml5Validate
                >
                  <Box width={260} height={40} mt={10} ml="auto" mr="auto">
                    <AppButton
                      label={t("bookingForm.button")}
                      onClick={() => null}
                      disableRipple={true}
                      customBackgroundColor={hotelInfo?.style?.button?.color}
                      customActiveColor={hotelInfo?.style?.button?.active}
                      customHoverColor={hotelInfo?.style?.button?.hover}
                      customClickColor={hotelInfo?.style?.button?.click}
                      type="submit"
                      fontSize="14px"
                      isLoading={isBookingLoading}
                      disabled={isBookingLoading}
                      dataAttribute="booking-offer-button"
                    />
                  </Box>
                </Form>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {showPaymentModal && (
        <BookingPaymentModal isOpen={showPaymentModal} offerCode={bookingState?.state?.offer?.offer_code} />
      )}
    </Grid>
  );
}
