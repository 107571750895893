import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ReactGA from "react-ga4";
// @ts-ignore
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
// Components
import HeadingBox from "../../../components/ui/HeadingBox";
import AppButton from "../../../components/ui/AppButton";
import AppCard from "../../../components/ui/AppCard";
// actions
import { useActions } from "../../../common/hooks/useActions";
import { confirmationActionCreators } from "../../../common/store/modules/bookingConfirmationModule";
import { globalActionCreators } from "../../../common/store/modules/globalModule";
// helpers
import { getButtonText } from "./helpers/getButtonText";
import { getHeadingMessage } from "./helpers/getHeadingMessage";
import { getHeadingIcon } from "./helpers/getHeadingsIcon";
import { getHeadingTitle } from "./helpers/getHeadingTitle";
import { getContactInfoLink } from "./helpers/getContactInfoLink";
import { getFormattedDateWithHoursMinutes } from "./helpers/getFormattedDateWithHoursMinutes";
import { isAllowedBookingStatus } from "../../../common/utils/helpers/isAllowedBookingStatus";
// icons
import { errorIcon, noImgPlaceholder } from "../../../common/vector";

interface BookingConfirmationInterface {
  hidePdf?: boolean;
}

export default function BookingConfirmation({ hidePdf }: BookingConfirmationInterface) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const language = localStorage.getItem("i18nextLng");

  // states
  const apiUrl = useSelector((state: any) => state.global.apiUrl);
  const hotelInfo = useSelector((state: any) => state.hotel.hotelInfo);
  const bookingStatus = useSelector((state: any) => state.confirmation.bookingStatus);
  const apiError = useSelector((state: any) => state.confirmation.apiError);
  const isBookingStatusLoading = useSelector((state: any) => state.confirmation.isBookingStatusLoading);
  const isLoadingDownloadPdf = useSelector((state: any) => state.confirmation.isLoadingDownloadPdf);

  // actions
  const getBookingStatus = useActions(confirmationActionCreators?.getBookingStatusAction, []);
  const downloadConfirmationPdf = useActions(confirmationActionCreators?.downloadConfirmationPdfAction, []);
  // yellow bookings check
  const isProcessingYellowBookings =
    !bookingStatus?.directly_bookable && bookingStatus?.payment_status === "authorized";
  const isBookingStatusAllowed: boolean = isAllowedBookingStatus(bookingStatus?.booking_status);

  useEffect(() => {
    // TODO: try to handle turning off global loader on the global level (AppLayout ?), instead of on the component
    // not show global loader on this component
    dispatch(globalActionCreators?.toggleGlobalLoaderAction(false));
    dispatch(globalActionCreators?.toggleLanguageMenuDisableAction(true));

    return () => {
      dispatch(globalActionCreators?.toggleLanguageMenuDisableAction(false));
    };
  }, []);

  useEffect(() => {
    // call getBookingStatus every 2 seconds until we get booking_status === "booked"
    const intervalId = setInterval(() => {
      // don't call getBookingStatus if status is booked or cancelled or there is error from BE API
      // or in case Yellow bookings (when directly_bookable is set to false) and for PAYMENT_STATUS we get AUTHORIZED
      // or if we get payment_status=paid (that means user booked offer with price 0)
      if (
        isProcessingYellowBookings ||
        bookingStatus?.booking_status === "booked" ||
        bookingStatus?.booking_status === "cancelled" ||
        bookingStatus?.payment_status === "paid" ||
        apiError
      ) {
        clearInterval(intervalId);
        return;
      } else {
        apiUrl && getBookingStatus();
      }
    }, 2000);

    // clear interval if we get "booked" status
    if (bookingStatus?.booking_status === "booked") {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [apiUrl, bookingStatus, apiError]);

  useEffect(() => {
    // GA4 - Track only bookings with booking status BOOKED
    if (bookingStatus && bookingStatus?.booking_status === "booked") {
      ReactGA.gtag("event", "purchase", {
        currency: bookingStatus?.total_price?.substring(0, 3), // TBD: BE should return to us separately currency and price (number)
        transaction_id: bookingStatus?.booking_code,
        value: bookingStatus?.total_price?.substring(4, bookingStatus?.total_price?.length), // TBD: BE should return to us separately currency and price (number)
        items: [
          {
            item_name: bookingStatus?.event_name,
            item_category: "booking-confirmation"
          }
        ]
      });
    }
  }, [bookingStatus]);

  const handleButtonClick = () => {
    navigate(`/${language}`, { replace: true });
  };

  return (
    <Box
      className="confirmation"
      sx={{ py: 8, px: { xs: 4, sm: 4, md: 12, lg: 22 }, display: "flex", flexDirection: "column", flex: 1 }}
    >
      {isBookingStatusLoading ? (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box mb={8}>{t("confirmation.preparing")}...</Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {apiError || bookingStatus?.booking_status === "error" || !isBookingStatusAllowed ? (
            <HeadingBox
              icon={errorIcon.default}
              title={t("confirmation.bookingError")}
              text={
                <span>
                  {t("confirmation.bookingErrorMessageInfo.infoText")}{" "}
                  <a href={getContactInfoLink(hotelInfo?.links)} target="_blank" rel="noreferrer">
                    {t("confirmation.bookingErrorMessageInfo.viaFormText")}
                  </a>{" "}
                  {t("confirmation.bookingErrorMessageInfo.viaEmailText")}{" "}
                  <a href={`mailto:support@get-local.com?subject=${bookingStatus?.booking_code}`}>
                    support@get-local.com
                  </a>
                </span>
              }
            />
          ) : (
            <HeadingBox
              icon={getHeadingIcon(bookingStatus?.booking_status)}
              title={getHeadingTitle(bookingStatus?.booking_status, t)}
              text={getHeadingMessage(bookingStatus?.booking_status, t)}
              separator={true}
            />
          )}

          {/*TODO: we should move imgSrcSet to the separate helper method (as on Offer Details Slider) */}
          {(isProcessingYellowBookings && !apiError) ||
          (bookingStatus?.booking_status === "booked" && !apiError) ||
          bookingStatus?.payment_status === "paid" ? (
            <Box sx={{ mt: 6 }}>
              <AppCard
                imgSrc={
                  (bookingStatus?.image_preview && bookingStatus?.image_preview?.dimensions?.[0]?.url) ||
                  noImgPlaceholder
                }
                imgSizes="(max-width: 600px) 40vw,
                (max-width: 900px) 361px,
                (max-width: 1200px) 494px,
                494px"
                imgSrcSet={`${bookingStatus?.image_preview?.dimensions?.[0]?.url} ${bookingStatus?.image_preview?.dimensions?.[0]?.width}w,${bookingStatus?.image_preview?.dimensions?.[1]?.url} ${bookingStatus?.image_preview?.dimensions?.[1]?.width}w,${bookingStatus?.image_preview?.dimensions?.[2]?.url} ${bookingStatus?.image_preview?.dimensions?.[2]?.width}w`}
                leftSection={
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box>
                      <span className="confirmation__booking-code">{bookingStatus?.booking_code}</span>
                      <span
                        className={`confirmation__booking-status ${
                          bookingStatus?.booking_status === "processing"
                            ? "confirmation__booking-status--yellow"
                            : bookingStatus?.booking_status === "error" || bookingStatus?.booking_status === "cancelled"
                            ? "confirmation__booking-status--error"
                            : ""
                        }`}
                      >
                        {bookingStatus?.booking_status === "processing"
                          ? t("confirmation.bookingRequestedLabel")
                          : bookingStatus?.booking_status_label}
                      </span>
                      <div className="confirmation__offer-name">{bookingStatus?.event_name}</div>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        justifyContent: "space-around",
                        fontFamily: "Neutraface2Light",
                        fontFeatureSettings: '"pnum" on, "lnum" on'
                      }}
                    >
                      <div>
                        {t("confirmation.bookingDate")}: {getFormattedDateWithHoursMinutes(bookingStatus?.booking_date)}
                      </div>
                      <div>
                        {t("confirmation.eventDate")}: {getFormattedDateWithHoursMinutes(bookingStatus?.event_date)}
                      </div>
                      <div>
                        {bookingStatus?.units?.length > 0 &&
                          bookingStatus?.units?.map((unit: any) => unit?.label).join(", ")}
                      </div>
                      <div>
                        {t("confirmation.totalPrice")}: {bookingStatus?.total_price}
                      </div>
                      <div>
                        {t("confirmation.hotel")}: {bookingStatus?.hotel_name}, {bookingStatus?.hotel_city}
                      </div>
                    </Box>
                  </Box>
                }
                rightSection={
                  bookingStatus?.booking_status !== "processing" && !hidePdf ? (
                    <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                      <Box sx={{ width: "100%" }}>
                        <AppButton
                          label={t("confirmation.printPdf")}
                          onClick={() =>
                            downloadConfirmationPdf(
                              bookingStatus?.pdf,
                              bookingStatus?.hash,
                              bookingStatus?.booking_code
                            )
                          }
                          variant="contained"
                          disableRipple={true}
                          customBackgroundColor={theme.palette.primary.light}
                          fontSize="14px"
                          isLoading={isLoadingDownloadPdf}
                          dataAttribute="print-pdf-button"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <div></div>
                  )
                }
              />
            </Box>
          ) : null}

          <Box sx={{ mt: 6, maxWidth: "350px" }} margin="0 auto">
            <AppButton
              label={getButtonText(apiError, bookingStatus?.booking_status, t)}
              onClick={handleButtonClick}
              variant="contained"
              disableRipple={true}
              customBackgroundColor={hotelInfo?.style?.button?.color}
              customActiveColor={hotelInfo?.style?.button?.active}
              customHoverColor={hotelInfo?.style?.button?.hover}
              customClickColor={hotelInfo?.style?.button?.click}
              fontSize="14px"
              dataAttribute="confirmation-button"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
